<template>
  <div class="hello">
    <h3>内网导航</h3>

    <div class="box">

      <div class="item" v-for="item in list" :key="item.title">
        <a :href="item.url">
          <div class="title">{{ item.title }}</div>
          <div class="desc">{{ item.desc }}</div>
        </a>

      </div>

    </div>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'Index',
  methods: {
    getconfig: function () {
      axios.get("/config.json").then((data) => {
        this.list = data.data;
      })

    }
  },
  created() {
    this.getconfig()
  },
  data() {
    return {
      list: []
    }
  }


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.box {
  margin-top: 30px;
}

a {
  text-decoration: none;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.item {
  float: left;
  width: 46%;
  margin: 0 1% 10px 1%;
  height: 100px;
  border: 1px solid #EEEEEE;
  box-shadow: 2px 2px 2px 1px rgb(238, 238, 238);
}

.title {
  margin-top: 7px;
  font-size: 14px;
}

.desc {
  margin-top: 10px;
  font-size: 11px;
  color: darkgray;
  height: 80px;
}


</style>
